<template lang="pug">
.info-card.card-sheet(:style="styles")
  .info-card__img(v-if="$slots.image")
    slot(name="image")
  .info-card__title(v-if="title") {{ title }}
  .info-card__content
    slot
</template>

<script>
  import { measurable } from '@/uikit/mixins/measurable';

  export default {
    mixins: [measurable],

    props: {
      title: { type: String, default: undefined },
    },

    computed: {
      styles() {
        return this.measurableStyles;
      },
    },
  };
</script>

<style lang="scss">
  .info-card {
    padding: 24px;
  }

  .info-card__img {
    margin-bottom: 24px;
  }

  .info-card__title {
    @include text-style('h4-mobile', 'light');
    color: c('gray100', 'light');
    margin-bottom: 8px;
  }

  .info-card__content {
    @include text-style('normal', 'light');
    color: c('gray80', 'light');
  }
</style>
