<template lang="pug">
.page-landing.page-landing--letter-of-credit.service-letter-of-credit
  section.page-landing__section.page-landing__section--dark.service-letter-of-credit__header
    h1.t-h1-big.mb-3.c-white {{ service.title }}
    p.t-subtitle.c-gray5.mb-10 {{ service.description }}

    template(v-if="asset")
      ui-btn(
        large
        primary
        width="100%"
        @click="openLetterOfCreditApplyDialog"
      )
        | Узнать подробнее

    template(v-else)
      .service-sberlot__header-info.mb-4
        ui-icon.c-primary(name="info" left)
        .t-caption-middle.c-gray10 Закажите услугу на странице актива

  section.page-landing__section.page-landing__section--white
    h1.t-h1-mobile.c-gray100.mb-6 Что такое аккредитив?
    p.t-normal.c-text <strong>Аккредитив</strong> – вид безопасного расчета, при&nbsp;котором Банк, действующий по&nbsp;поручению Покупателя, обязуется произвести платеж в&nbsp;пользу Продавца, при&nbsp;представлении документов, соответствующих условиям аккредитива.

    v-divider.my-10

    h1.t-h1-mobile.c-gray100.mb-6.mb-8 Преимущества
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="case-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Удобно
        .t-middle.c-gray80 Аккредитив можно открыть в&nbsp;любом офисе СберБанка или в&nbsp;мобильном приложении
    .d-flex.mb-6
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="shield-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Безопасно
        .t-middle.c-gray80 Гарантия защиты всем сторонам сделки, в&nbsp;том числе участникам цепочки сделок
    .d-flex
      .icon-in-circle.bg-white.sh-small
        ui-icon.c-primary60(name="timer-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Быстро
        .t-middle.c-gray80 Открытие аккредитива в&nbsp;течение 10&nbsp;минут в&nbsp;офисе и&nbsp;1&nbsp;минуты онлайн, исполнение аккредитива в&nbsp;течение 1&nbsp;дня

  section.page-landing__section.page-landing__section--dark.service-letter-of-credit__section2
    h2.t-h1-mobile.mb-8.c-white Для каких сделок подойдет аккредитив
    info-card.mb-4
      template(#image)
        .icon-in-circle.bg-gray5
          .icon.icon-company.icon-24.c-gray80
          .icon-in-circle__overlay-icon
            .icon.icon-check-circle-green--colored
      .t-h3-mobile.c-gray100.mb-2 Продаёте или&nbsp;покупаете недвижимость&nbsp;—
      .t-middle.c-gray80 в&nbsp;том числе в&nbsp;ипотеку или&nbsp;участвуете в&nbsp;цепочке сделок
    info-card.mb-4
      template(#image)
        .icon-in-circle.bg-gray5
          .icon.icon-asset.icon-24.c-gray80
          .icon-in-circle__overlay-icon
            .icon.icon-check-circle-green--colored
      .t-h3-mobile.c-gray100.mb-2 Продаёте или покупаете дорогое имущество&nbsp;—
      .t-middle.c-gray80 например, оборудование, автомобиль, ценные бумаги, долю в уставном капитале.
    info-card.mb-4
      template(#image)
        .icon-in-circle.bg-gray5
          .icon.icon-account-tie.icon-24.c-gray80
          .icon-in-circle__overlay-icon
            .icon.icon-check-circle-green--colored
      .t-h3-mobile.c-gray100.mb-2 Оплачиваете дорогостоящие услуги&nbsp;—
      .t-middle.c-gray80 как физических, так и юридических лиц.

  section.page-landing__section
    h2.t-h2-mobile.mb-8.c-gray100 Тарифы на аккредитив
    .card-sheet.pa-6
      table.simple-table
        thead.t-small.c-gray70
          tr
            td Тип сделки
            td Комиссия
        tbody
          tr.c-gray80
            td.t-caption-middle-bold Сделки с недвижимостью
            td
          tr.c-gray80
            td.t-caption-middle За счет собственных средств
            td.t-caption-normal-bold 2000&nbsp;₽
          tr.simple-table__row--bordered.c-gray80
            td.t-caption-middle С участием кредитных средств Сбера
            td.t-caption-normal-bold 3400&nbsp;₽

          tr.c-gray80
            td.t-caption-middle-bold Вне сделок с недвижимостью
            td
              .t-caption-normal-bold.mb-2 0,2%
              .t-small.c-gray70 мин&nbsp;500&nbsp;₽
              .t-small.c-gray70 макс&nbsp;10000&nbsp;₽

    h2.t-h2-mobile.c-gray100.mb-8.mt-14 Для открытия аккредитива нужен только паспорт и договор

    service-order-card.mt-8(:asset="asset" :service="service")
      template(#howto)
        .t-normal.c-gray10 Выберите интересующий вас актив в&nbsp;каталоге. На&nbsp;странице актива нажмите кнопку &laquo;Безопасная сделка&raquo;.

      template(#apply)
        h2.t-h1-mobile.mb-4.c-white Узнайте больше о&nbsp;проведении безопасной сделки
        ui-btn(
          large
          primary
          width="100%"
          @click="openLetterOfCreditApplyDialog"
        )
          | Узнать подробнее

  section.page-landing__section.page-landing__section--white
    h2.t-h1-mobile.mb-8.c-gray100 Сервис предоставляет
    img.mb-6(src="~@/assets/logos/sber-logo.svg")
    .t-h5.c-gray100.mb-3 ПАО Сбербанк
    p.t-normal.c-text.mb-0 ПАО Сбербанк — крупнейшний банк в&nbsp;России, Центральной и&nbsp;Восточной Европе, один из ведущих международных финансовых институтов

  letter-of-credit-apply-dialog(
    v-if="asset"
    ref="letterOfCreditApplyDialog"
    :asset-id="asset.short_id"
  )
</template>

<script>
  import { UiIcon, UiBtn, UiExpansionPanel } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import ServiceOrderCard from '@/components/pages/ServiceOrderCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';
  import LetterOfCreditApplyDialog from '@/components/services/LetterOfCreditApplyDialog.vue';
  import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiExpansionPanel,
      InfoCard,
      ServiceOrderCard,
      CircleIcon,
      LetterOfCreditApplyDialog,
    },
    inject: ['requireAuth'],

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    methods: {
      openLetterOfCreditApplyDialog() {
        if (this.asset.politics.can_ask) {
          seoEventService.safeTriggerEvent(this.asset.short_id, this.service.name, [
            'landing',
            'application',
            'web_mobile',
          ]);
          this.$refs.letterOfCreditApplyDialog.open();
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>

<style lang="scss">
  .service-letter-of-credit {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/letter_of_credit-bg.jpeg') no-repeat white;
      background-size: cover;

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__section2 {
      background: url('~@/assets/letter_of_credit-bg2.jpeg') no-repeat white;
      background-size: cover;
    }

    table.simple-table {
      width: 100%;
      border-bottom: 1px solid c('black10', 'light');
      border-collapse: collapse;

      td {
        padding: 12px 0;
        vertical-align: top;
      }

      tr.simple-table__row--bordered {
        td {
          border-bottom: 1px solid c('black10', 'light');
        }
      }

      thead {
        td {
          border-bottom: 2px solid c('black80', 'light');
        }
      }

      tbody {
        tr {
          td:last-child {
            text-align: right;
          }
        }
      }
    }
  }
</style>
