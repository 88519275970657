<template lang="pug">
.page-landing.page-landing--sberlot.service-sberlot
  section.page-landing__section.page-landing__section--dark.service-sberlot__header
    h1.t-h1-big.mb-3.c-white {{ service.title }}
    p.t-subtitle.c-gray5.mb-10 {{ service.description }}
    .d-flex.align-center.mb-5
      .asset-price.asset-price--large.c-white {{ service.tariff_price_formatted }}
      .t-caption-xsmall.c-text--dimmed.ml-3(style="width: 110px; line-height: 13px") Стоимость агентских услуг

    template(v-if="asset")
      ui-btn.mb-4(
        large
        primary
        width="100%"
        @click="apply"
      ) Отправить заявку

    template(v-else)
      .service-sberlot__header-info.mb-4
        ui-icon.c-primary(name="info" left)
        .t-caption-middle.c-gray10 Закажите услугу на странице актива

    span.t-h4.c-white(style="font-weight: 500") Консультация&nbsp;—&nbsp;
    span.t-h4.c-white 8 800 770 07 72

  section.page-landing__section.page-landing__section--white.px-8.pb-16
    h2.t-h2-mobile.mb-8.c-black100 Для самостоятельной покупки вам необходимо:
    .d-flex.align-start.mb-6
      .t-h1-profile.c-primary60 1
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Собрать документы согласно требованиям конкретной торговой площадки
        .t-middle.c-gray80.mt-1 Cканы документов могут быть отклонены по множеству мелких причин: тон текста, изгибы документов, тень и т.п.
    .d-flex.align-start.mb-6
      .t-h1-profile.c-primary60 2
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Купить электронную цифровую подпись
        .t-middle.c-gray80.mt-1 Стоимость ЭЦП до 17 000 руб. Они бывают разных видов, есть риск приобрести неподходящую для данной площадки
    .d-flex.align-start.mb-6
      .t-h1-profile.c-primary60 3
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Пройти аккредитацию на электронной торговой площадке
        .t-middle.c-gray80.mt-1 В случае ошибки в документах или шагах – отказ в аккредитации
    .d-flex.align-start.mb-6
      .t-h1-profile.c-primary60 4
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Внести задаток
        .t-middle.c-gray80.mt-1 Организатор торгов может отклонить заявку по причине отсутствия задатка, важно правильно указать назначение платежа, реквизиты и т.д.
    .d-flex.align-start.mb-6
      .t-h1-profile.c-primary60 5
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Принять участие в торгах
        .t-middle.c-gray80.mt-1 Участнику торгов дается до 30 минут, чтобы сделать ставку. Если не успеть перебить цену другого участника, есть риск проиграть торги
    .d-flex.align-start
      .t-h1-profile.c-primary60 6
      .ml-3.mt-1
        .t-normal-bold.c-gray100 Подписать договор купли-продажи
        .t-middle.c-gray80.mt-1 Если не сделать это в указанные сроки (возможен срок не более 5 дней) результаты торгов будут аннулированы

  section.page-landing__section.page-landing__section--dark.service-sberlot__section2
    h2.t-h1-mobile.mb-8.c-white Агент по торгам возьмет на себя весь процесс от подачи заявки до участия в торгах
    info-card.mb-4
      ui-icon.mb-4(name="file-document-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Агент поможет избежать ошибок при подготовке документов, подаче заявки, регистрации на ЭТП и в ходе торгов
    info-card.mb-4
      ui-icon.mb-4(name="pen-plus-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Вы избежите необходимости покупки электронной цифровой подписи
    info-card.mb-4
      ui-icon.mb-4(name="clock-time-four-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Вы сэкономите время на прохождение аккредитации на электронной торговой площадке
    info-card.mb-4
      ui-icon.mb-4(name="percent-circle-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Опыт профессионального агента позволит приобрести актив на выгодных условиях
    info-card.mb-4
      ui-icon.mb-4(name="message-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Агент проконсультирует вас о дальнейших действиях после завершения торгов
    info-card
      ui-icon.mb-4(name="case-check-bg-checked" originalColor size=65)
      .t-middle.c-gray80 Услуга доступна для физических и юридических лиц со всей России, дистанционно

  section.page-landing__section.page-landing__section--white
    .t-h1-mobile.mb-8 Преимущества<br>привлечения агента
    .d-flex.align-center.mb-6
      .sberlot-advantages__icon-wrapper
        ui-icon.c-primary60(name="time-2")
      .t-normal.mr-16 Заключение договора через&nbsp;сайт за&nbsp;2&nbsp;минуты
    .d-flex.align-center.mb-6
      .sberlot-advantages__icon-wrapper
        ui-icon.c-primary60(name="rouble-cycle")
      .t-normal.mr-16 Оплата услуги и задатков в&nbsp;2&nbsp;клика
    .d-flex.align-center.mb-2
      .sberlot-advantages__icon-wrapper
        ui-icon.c-primary60(name="asset-search")
      .t-normal.mr-16 Удобный контроль всех этапов

  section.page-landing__section.service-sberlot__advantages-block
    h2.t-h1-mobile.mb-8 Всё, что от вас потребуется:
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/desktop-rect-plus.svg")
          .t-huge-number.ml-auto.c-gray5 1
      .t-h3-mobile.c-gray100.mb-2 Подать заявку на Портале DA
      .t-middle.c-gray80 Выберите интересующий вас объект на Портале DA, который реализуется на торгах по банкротству и подайте заявку на привлечения агента для участия в торгах
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/pay.svg")
          .t-huge-number.ml-auto.c-gray5 2
      .t-h3-mobile.c-gray100.mb-2 Оплатить услуги агента
      span.t-middle.c-gray80 Произведите оплату и направьте агенту документ, подтверждающий оплату. Оплачивая услуги агента, Вы соглашаетесь с условиями&nbsp;
      a.link(
        href=`${process.env.VUE_APP_API_HOST}/documents/services/sberlot/offer.pdf?v2`
        rel="nofollow"
        target="_blank"
      ) Оферты
      | .
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/files.svg")
          .t-huge-number.ml-auto.c-gray5 3
      .t-h3-mobile.c-gray100.mb-2 Направить агенту необходимые документы для участия в торгах
      .t-middle.c-gray80 Вы можете загрузить документы в заявке или агент запросит их позже

    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/service-credit.svg")
          .t-huge-number.ml-auto.c-gray5 4
      .t-h3-mobile.c-gray100.mb-2 Заключить договор купли-продажи
      .t-middle.c-gray80 В случае победы на торгах заключите договор купли-продажи

  section.page-landing__section.page-landing__section--white
    h2.t-h1-mobile.mb-8.c-black100 Об агенте
    img.mb-6(src="~@/assets/logos/abk-logo.svg")
    .t-h5.c-black100.mb-3 ООО ПКО «АБК»<br>(дочерняя компания ПАО Сбербанк)
    p.t-middle.c-text.mb-0 АктивБизнесКонсалт – это технологическая компания-разработчик передовых продуктов для бизнеса в области речевых технологий и урегулирования задолженности

  section.page-landing__section
    service-order-card(:asset="asset" :service="service" @click="apply")
      template(#howto)
        .t-normal.c-gray10.mb-5 Выберите актив, участвующий в торгах. На странице актива нажмите кнопку «Помощь агента на торгах»

      template(#apply)
        .d-flex.align-center.mb-5(v-if="service && service.tariff_price_formatted")
          .t-h1-profile.c-white.mr-3 {{ service.tariff_price_formatted }}
          .t-caption-xsmall.c-gray60(style="line-height: 13px") Стоимость<br>агентских услуг

        ui-btn(
          large
          primary
          width="100%"
          @click="apply"
        ) Отправить заявку
</template>

<script>
  import { UiIcon, UiBtn, UiExpansionPanel } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import ServiceOrderCard from '@/components/pages/ServiceOrderCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiExpansionPanel,
      InfoCard,
      ServiceOrderCard,
      CircleIcon,
      ApplyServiceAssetCard,
    },

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    methods: {
      apply() {
        this.$emit('click:apply');
      },
    },
  };
</script>

<style lang="scss">
  .service-sberlot {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/sberlot-bg.jpg') no-repeat white;
      background-size: cover;
      border-radius: 0 0 12px 12px;

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__section2 {
      background: url('~@/assets/sberlot-bg2.jpg') no-repeat white;
      background-size: cover;
      border-radius: 12px;
      padding-bottom: 64px;
      .card-sheet {
        border-radius: 8px;
        padding: 32px 24px 40px;
      }
    }

    &__advantages-block {
      .card-sheet {
        box-shadow: 0px 2px 16px 0px rgba(73, 82, 116, 0.08),
          0px 1px 2px 0px rgba(73, 82, 116, 0.08);
      }
    }

    .t-caption-middle {
      line-height: 20px;
    }

    .sberlot-advantages__icon-wrapper {
      padding: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 16px 0px rgba(73, 82, 116, 0.08);
      margin-right: 16px;
    }
  }
</style>
