<template lang="pug">
.page-landing.page-landing--credit-drpa.service-credit-drpa
  section.service-credit-drpa__header.page-landing__section.page-landing__section--dark
    h1.t-h1-big.mb-3.c-white {{ service.title }}
    p.t-subtitle.c-gray5.mb-10 {{ service.description }}
    .d-flex.align-center.mb-4
      .asset-price.asset-price--large.c-white {{ service.tariff_price_description }}
      .t-small.c-text--dimmed.ml-3 Стоимость агентских услуг

    template(v-if="asset")
      ui-btn.mb-3(
        large
        primary
        width="100%"
        @click="apply"
      ) Отправить заявку
      .d-flex.align-center
        ui-icon.c-primary(name="info")
        .t-small.c-gray10 Стоимость вы сможете оговорить с менеджером

    template(v-else)
      .service-credit-drpa__header-info
        ui-icon.c-primary(name="info" left)
        div
          .t-caption-middle.c-gray10.mb-1 Закажите услугу на странице актива
          .t-small.c-black60 Стоимость вы сможете оговорить с менеджером

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Преимущества
    .d-flex.align-center.mb-6
      circle-icon(color="gray10")
        ui-icon(name="timer-check")
      .ml-4
        p.t-caption-middle.mb-0.c-black100 Быстрое рассмотрение заявки
    .d-flex.align-center.mb-6
      circle-icon(color="gray10")
        ui-icon(name="files-2")
      .ml-4
        p.t-caption-middle.mb-0.c-black100 Собственное участие – не менее 30%

  section.page-landing__section.service-credit-drpa__report
    h2.t-h1-mobile.mb-8.c-white Условия выдачи кредита
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="account-tie")
      p.t-caption-middle.mb-0.c-black100 Минимальный пакет документов
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="calendar-clock")
      p.t-caption-middle.mb-0.c-black100 Срок – до 3-лет
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="shield-lock")
      p.t-caption-middle.mb-0.c-black100 Обеспечение – залог приобретаемого на торгах имущества
    info-card.mb-4(title="Особые условия")
      .mt-3 Кредит предоставляется юридическому лицу или индивидуальному предпринимателю – резиденту РФ, не имеющему кредитных обязательств или предодобренных лимитов в ПАО Сбербанк.

  section.page-landing__section
    h2.t-h1-mobile.mb-8 Этапы работы
    info-card.mb-4(title="Оформить заявку на кредит")
      template(#image)
        .d-flex
          img(src="~@/assets/96/messages.svg")
          .t-huge-number.ml-auto.c-gray5 1
    info-card.mb-4(title="Победить на торгах")
      template(#image)
        .d-flex
          img(src="~@/assets/96/sell-2.svg")
          .t-huge-number.ml-auto.c-gray5 2
      p.t-caption-middle.mb-0.c-black100 Поможет
        a.link.c-primary60.ml-1(href="/services/sberlot") Агент на торгах
    info-card.mb-4(title="Оформить сделку")
      template(#image)
        .d-flex
          img(src="~@/assets/96/to-rent.svg")
          .t-huge-number.ml-auto.c-gray5 3

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Об агенте
    img.mb-6(src="~@/assets/logos/sber-logo.svg")
    .t-h5.c-black100.mb-3 ПАО Сбербанк
    p.t-normal.c-text.mb-0 ПАО Сбербанк — крупнейшний банк в России, Центральной и Восточной Европе, один из ведущих международных финансовых институтов

  section.page-landing__section
    how-to-order-card(v-if="!asset")
      | Выберите актив, участвующий в торгах. На странице актива в блоке услуги нажмите «Заказать»
    apply-service-asset-card(v-else :asset="asset" @click="apply")
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import HowToOrderCard from '@/components/pages/HowToOrderCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';

  export default {
    components: { UiIcon, UiBtn, CircleIcon, InfoCard, HowToOrderCard, ApplyServiceAssetCard },
    inject: ['requireAuth'],
    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    methods: {
      apply() {
        if (!this.asset) return;

        if (this.asset.politics.can_ask) {
          window.location = this.$path.creditDrpaServiceApplication(this.asset.short_id);
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>

<style lang="scss">
  .service-credit-drpa {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/credit_drpa-1.jpeg') no-repeat white;
      background-size: cover;

      &-logo {
        display: flex;
        margin-bottom: 24px;

        img:first-child {
          border-right: 1px solid c('black80', 'light');
          margin-right: 16px;
          padding-right: 16px;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__report {
      background: url('~@/assets/enforcement-sberlot-bg2.jpeg') no-repeat white;
      background-size: cover;
    }
  }
</style>
