<template lang="pug">
app-layout
  v-app.layout-full.page-service
    template(v-if="!loading")
      ui-btn.page-service__back(
        plain
        text
        :to="asset ? $path.asset(asset.short_id) : $path.services()"
      )
        ui-icon.c-black80(name="arrow-left")
        | {{ asset ? "Назад к активу" : "Все сервисы" }}

      template(v-if="serviceComponent")
        component(
          :asset="asset"
          :asset-id="assetId"
          :is="serviceComponent"
          :service="service"
          @click:apply="apply"
        )
      template(v-else)
        | такого сервиса не найдено '{{ serviceName }}'

    // загрузка идёт
    template(v-else)
      v-overlay(color="white" opacity="1")
        ui-loader.my-6.px-4(big text="Загрузка...")
</template>

<script>
  import { UiIcon, UiBtn, UiLoader } from '@/uikit';
  import AppLayout from '@/layouts/AppLayout';
  import { service as ServicesAPI } from '@/services/services';
  import { service as AssetsAPI } from '@/services/assets';

  // лендосики сервисов
  import agroai from '@/views/services/agroai.vue';
  import sberlot from '@/views/services/sberlot.vue';
  import credit_potential from '@/views/services/credit_potential.vue';
  import enforcement_sberlot from '@/views/services/enforcement_sberlot.vue';
  import credit from '@/views/services/credit.vue';
  import credit_drpa from '@/views/services/credit_drpa.vue';
  import letter_of_credit from '@/views/services/letter_of_credit.vue';

  import { service as seoEventService } from '@/services/seo-events/seo-event-service.js';

  import { handleError } from '@/libs/handle-error';
  import { createLogger } from '@/uikit/util/logger';
  const logger = createLogger('PageServices', 'blue');

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiLoader,
      AppLayout,
      agroai,
      sberlot,
      enforcement_sberlot,
      credit,
      credit_drpa,
      letter_of_credit,
      credit_potential,
    },
    inject: ['requireAuth', 'openDesktop'],

    props: {
      serviceName: { type: String, default: undefined },
    },

    data: () => ({
      services: [],
      loading: true,
      assetId: undefined,
      asset: undefined,
    }),

    computed: {
      currentUser() {
        return this.$store.getters['currentUser'];
      },

      service() {
        return this.services.find((s) => s.name === this.serviceName);
      },

      serviceComponent() {
        // проверяем по списку доступных сервисов
        const servicesAvailable = this.services ? this.services.map((s) => s.name) : [];
        if (servicesAvailable.length) {
          return servicesAvailable.includes(this.serviceName) ? this.serviceName : undefined;
        } else {
          return undefined;
        }
      },
    },

    watch: {
      '$route.query': {
        handler(query) {
          this.assetId = query && query.asset_id ? query.asset_id : undefined;
          this.fetchAsset(this.assetId);
        },
        deep: true,
        immediate: true,
      },
    },

    mounted() {
      this.fetchServices();
    },

    methods: {
      async fetchServices() {
        try {
          this.loading = true;
          this.services = await ServicesAPI.getServices();
        } catch (error) {
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      async fetchAsset(assetId) {
        try {
          this.loading = true;
          if (assetId) {
            this.asset = await AssetsAPI.getAsset(assetId);
          }
        } catch (error) {
          this.asset = undefined;
          handleError(error, logger);
        } finally {
          this.loading = false;
        }
      },

      // редирект на полную версию сайта для оформления заявки по сервису
      apply() {
        if (!this.asset) return;

        if (this.asset.politics.can_ask) {
          const assetService = this.asset.available_services.find(
            (s) => s.name === this.serviceName
          );

          seoEventService.safeTriggerEvent(this.assetId, this.serviceName, [
            'landing',
            'application',
            'web_mobile',
          ]);

          this.openDesktop({
            path: assetService.application_url,
            text: 'Оформление заявки недоступно в мобильной версии. Для удобства, рекомендуем отрыть полную версию сайта',
          });
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>

<style lang="scss">
  .page-service {
    &__back.v-btn {
      position: absolute !important;
      width: fit-content;
      left: 16px;
      top: 24px;
      padding: 2px 16px 2px 6px !important;
      display: flex;
      align-content: center;
      justify-content: space-between;
      background: transparent;
      color: c('black10', 'light') !important;
      border: 1px solid c('black80', 'light');

      // перекрыл стили vuetify
      span {
        opacity: 1 !important;
      }
    }
  }
</style>
