<template lang="pug">
.circle-icon
  v-avatar(:color="color ? 'bg-' + color : false" size=56)
    slot
  ui-icon.circle-icon__checked(v-if="checked" name="sber-check--colored")
</template>

<script>
  import { UiIcon } from '@/uikit';

  export default {
    components: { UiIcon },
    props: {
      name: { type: String, default: '' },
      color: { type: String, default: undefined },
      checked: { type: Boolean, default: false },
    },
  };
</script>

<style lang="scss">
  .circle-icon {
    position: relative;
    width: 56px;
    height: 56px;

    &__checked {
      position: absolute;
      right: -9px;
      bottom: 0;
    }
  }
</style>
