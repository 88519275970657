<template lang="pug">
.apply-service-asset-card(v-if="asset")
  .t-h1-mobile.pa-6 Отправьте заявку на интересующий актив
  asset-card(:asset="asset")
  .px-4.py-6
    ui-btn(
      large
      primary
      width="100%"
      @click="$emit('click')"
    ) Отправить заявку
</template>

<script>
  import { UiBtn } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard';

  export default {
    components: { UiBtn, AssetCard },
    props: {
      asset: { type: Object, default: undefined },
    },
  };
</script>

<style lang="scss">
  .apply-service-asset-card {
    border-radius: 4px;
    background: c('white', 'light');
  }
</style>
