<template lang="pug">
.page-landing.service-credit-potential
  section.page-landing__section.page-landing__section--dark.service-credit-potential__header
    h1.t-h1-big.mb-3.c-white Кредитный потенциал<br />для&nbsp;бизнеса
    p.t-subtitle.c-gray5.mb-10 Узнайте одобренную сумму<br />и&nbsp;получите кредит по&nbsp;ставке на&nbsp;3%&nbsp;ниже

    ui-btn.ui-append-icon.mb-3(
      large
      primary
      width="100%"
      @click="clickSBClientLand(assetId)"
    )
      | Клиент СберБизнес
      ui-icon.ui-append-icon__icon(name="open-in-new")
    ui-btn.ui-append-icon(
      large
      primary
      width="100%"
      @click="$refs.creditPotentialApplyDialog.open()"
    )
      | Не клиент СберБизнес
      ui-icon.ui-append-icon__icon(name="open-in-new")

  section.page-landing__section.page-landing__section--white.px-4.pb-16
    .t-h1-mobile.c-gray100.mb-6 Преимущества сервиса
    .d-flex.mb-6
      .icon-in-circle.c-icon--white.sh-small
        ui-icon.c-primary60(name="timer-check")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Расчёт онлайн за&nbsp;3&nbsp;минуты
        .t-middle.c-gray80 В&nbsp;интернет-банке и&nbsp;мобильном приложении СберБизнес, а&nbsp;для&nbsp;ИП — в&nbsp;СберБанк Онлайн
    .d-flex.mb-6
      .icon-in-circle.c-icon--white.sh-small
        ui-icon.c-primary60(name="file-document")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Без открытия счёта и&nbsp;документов
        .t-middle.c-gray80 Расчёт кредитного потенциала не&nbsp;является заявкой на&nbsp;кредит
    .d-flex
      .icon-in-circle.c-icon--white.sh-small
        ui-icon.c-primary60(name="percent-circle")
      .ml-4.mt-2
        .t-normal-bold.c-gray100.mb-1 Ставка по&nbsp;кредиту ниже на&nbsp;3%
        .t-middle.c-gray80 Есть время на&nbsp;принятие решения<br />о&nbsp;подаче заявки на&nbsp;кредит

  section.page-landing__section.page-landing__section--dark.service-credit-potential__section2
    h2.t-h1-mobile.mb-8.c-white Прогнозируйте свои финансовые возможности
    info-card.mb-4
      .icon-in-circle.c-icon--gray5.mb-4
        .icon.icon-case-check.icon-24.c-gray80
        .icon-in-circle__overlay-icon
          .icon.icon-check-circle-green--colored
      .t-middle.c-gray80 Узнайте одобренную сумму кредита, банковской гарантии или лимит по&nbsp;кредитной бизнес-карте
    info-card.mb-4
      .icon-in-circle.c-icon--gray5.mb-4
        .icon.icon-calendar-clock.icon-24.c-gray80
        .icon-in-circle__overlay-icon
          .icon.icon-check-circle-green--colored
      .t-middle.c-gray80 Получите информацию по&nbsp;сроку кредита, процентной ставке и&nbsp;необходимости залога
    info-card.mb-4
      .icon-in-circle.c-icon--gray5.mb-4
        .icon.icon-file-document.icon-24.c-gray80
        .icon-in-circle__overlay-icon
          .icon.icon-check-circle-green--colored
      .t-middle.c-gray80 Подайте онлайн-заявку и&nbsp;получите кредит без залога на&nbsp;любые цели бизнеса по&nbsp;ставке на&nbsp;3%&nbsp;ниже
    info-card.mb-4
      .icon-in-circle.c-icon--gray5.mb-4
        .icon.icon-user.icon-24.c-gray80
        .icon-in-circle__overlay-icon
          .icon.icon-check-circle-green--colored
      .t-middle.c-gray80 Доступно для клиентов и&nbsp;неклиентов банка

  section.page-landing__section.service-credit-potential__advantages-block
    h2.t-h1-mobile.mb-8 Как узнать кредитный потенциал
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/login.svg")
          .t-huge-number.ml-auto.c-gray10 1
      .t-h3-mobile.c-gray100.mb-2 Перейдите в&nbsp;интернет-банк или&nbsp;мобильное приложение СберБизнес, а если вы ИП — в&nbsp;СберБанк Онлайн для физлиц
      .t-middle.c-gray80 В&nbsp;разделе «Кредиты» вы сможете сами рассчитать свой кредитный потенциал. Если вы ИП без&nbsp;расчётного счёта в&nbsp;Сбере — в&nbsp;веб-версии СберБанк Онлайн для&nbsp;физлиц найдите категорию «Все продукты», «Бизнесу» и&nbsp;выберите «Бизнес-потенциал». Для расчёта в&nbsp;мобильном приложении зайдите в&nbsp;раздел «Кредиты», нажмите «+», «Бизнес-потенциал»
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/files.svg")
          .t-huge-number.ml-auto.c-gray10 2
      .t-h3-mobile.c-gray100.mb-2 Заполните анкету — это займёт не&nbsp;больше 3&nbsp;минут
      .t-middle.c-gray80 После этого подпишите согласие на&nbsp;обработку персональных данных
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/rent4.svg")
          .t-huge-number.ml-auto.c-gray10 3
      .t-h3-mobile.c-gray100.mb-2 Получите результат, не&nbsp;выходя из&nbsp;интернет-банка&nbsp;или мобильного приложения
      .t-middle.c-gray80 На&nbsp;экране отобразится одобренная сумма на&nbsp;разные цели бизнеса
    info-card.mb-4
      template(#image)
        .d-flex
          img(src="~@/assets/96/buy.svg")
          .t-huge-number.ml-auto.c-gray10 4
      .t-h3-mobile.c-gray100.mb-2 Заполните заявку на&nbsp;кредит онлайн в&nbsp;течение 30&nbsp;дней и&nbsp;получите деньги на&nbsp;счёт в&nbsp;тот&nbsp;же&nbsp;день

  section.page-landing__section.page-landing__section--white
    .t-h1-mobile.mb-3 Скидка на&nbsp;ставку по&nbsp;кредиту
    .t-middle.c-gray80 Рассчитайте вашу скидку. Например, при оборотах по&nbsp;счету 1&nbsp;млн&nbsp;₽ и&nbsp;расходах по&nbsp;бизнес-карте 100&nbsp;000&nbsp;₽, вы&nbsp;получите скидку 1%&nbsp;годовых. Суммарная скидка за&nbsp;обороты не&nbsp;может быть больше&nbsp;2%.

    .t-h3-mobile.mt-8 Расчётный счет
    table.table-standard.mb-8
      thead
        th.border-light(style="width: 130px") Скидка
        th.border-light.text-left Обороты в&nbsp;месяц
      tr
        td.borderless.c-primary.text--bold 2%
        td.borderless.text-left от 4&nbsp;млн&nbsp;₽
      tr
        td.borderless.c-primary.text--bold 1,5%
        td.borderless.text-left от 3&nbsp;млн.₽
      tr
        td.borderless.c-primary.text--bold 0,5%
        td.borderless.text-left от 1&nbsp;млн&nbsp;₽

    .t-h3-mobile.mt-8 Бизнес-карта
    table.table-standard.mb-8
      thead
        th.border-light(style="width: 130px") Скидка
        th.border-light.text-left Обороты в&nbsp;месяц
      tr
        td.borderless.c-primary.text--bold 2%
        td.borderless.text-left от 400&nbsp;000&nbsp;₽
      tr
        td.borderless.c-primary.text--bold 1,5%
        td.borderless.text-left от 300&nbsp;000&nbsp;₽
      tr
        td.borderless.c-primary.text--bold 0,5%
        td.borderless.text-left от 100&nbsp;000&nbsp;₽

    .t-h3-mobile.mt-8 Зарплатный проект
    table.table-standard.mb-8
      thead
        th.border-light(style="width: 130px") Скидка
        th.border-light.text-left Обороты в&nbsp;месяц
      tr
        td.borderless.c-primary.text--bold 2%
        td.borderless.text-left от 1&nbsp;млн&nbsp;₽
      tr
        td.borderless.c-primary.text--bold 1,5%
        td.borderless.text-left от 750&nbsp;000&nbsp;₽
      tr
        td.borderless.c-primary.text--bold 0,5%
        td.borderless.text-left от 250&nbsp;000&nbsp;₽

    ui-badge.ui-badge--no-shadow.mb-2(color="green" large)
      .text--bold Дополнительная скидка 1%
    .t-small.c-text.mb-10 При страховании жизни заемщика – ИП, если оформляете заявку на&nbsp;кредит в&nbsp;веб-версии интернет-банка СберБизнес

    .t-h3-mobile.mb-3 Как считается скидка
    ul.bullet-list.c-text.pl-0
      li.bullet-list__item.bullet-list__item--checked.t-middle 0,1% за каждые 200&nbsp;000&nbsp;₽, поступивших на&nbsp;расчетный счет
      li.bullet-list__item.bullet-list__item--checked.t-middle 0,1% за каждые 50&nbsp;000&nbsp;₽, потраченных в&nbsp;рамках зарплатного проекта
      li.bullet-list__item.bullet-list__item--checked.t-middle 0,1% за каждые 20&nbsp;000&nbsp;₽, потраченных по&nbsp;бизнес-карте

  section.page-landing__section.px-4(style="padding: 96px 0 64px 0")
    .service-page-how-to-order--credit-potential
      .service-page-dark-card(style="position: relative; padding: 220px 16px 48px 16px")
        .t-h1-mobile.c-white.mb-6 Узнайте кредитный потенциал вашего бизнеса
        div
          ui-btn.ui-append-icon.mb-3.d-flex(
            large
            primary
            width="100%"
            @click="clickSBClientLand(assetId)"
          )
            | Клиент СберБизнес
            ui-icon.ui-append-icon__icon(name="open-in-new")
          ui-btn.ui-append-icon.d-flex(
            large
            primary
            width="100%"
            @click="$refs.creditPotentialApplyDialog.open()"
          )
            | Не клиент СберБизнес
            ui-icon.ui-append-icon__icon(name="open-in-new")
        .rounded-12.sh-large(
          style="overflow: hidden; position: absolute; left: 50%; top: -32px; height: 212px; transform: translateX(-50%)"
        )
          img(src="~@/assets/how-to-order-credit--potential.jpg" width=188 height=212)

  section.page-landing__section.page-landing__section--white
    h2.t-h1-mobile.mb-8.c-gray100 Сервис предоставляет
    img.mb-6(src="~@/assets/logos/sber-logo.svg")
    .t-h5.c-gray100.mb-3 ПАО Сбербанк
    p.t-normal.c-text.mb-0 ПАО Сбербанк — крупнейшний банк в&nbsp;России, Центральной и&nbsp;Восточной Европе, один из ведущих международных финансовых институтов

  section.page-landing__section
    p.t-small.c-gray80 Интернет-банк СберБизнес (ранее СберБанк Бизнес Онлайн) — система дистанционного банковского обслуживания для юридических лиц и&nbsp;индивидуальных предпринимателей, позволяющая клиентам банка получать через интернет широкий спектр банковских услуг. в&nbsp;отношении информационной продукции без&nbsp;ограничения по&nbsp;возрасту. Подробную информацию об интернет-банке СберБизнес, условиях его подключения, стоимости и&nbsp;тарифах, а также об имеющихся ограничениях на&nbsp;www.sberbank.ru, по телефону контактного центра 8 800 555-5-777 или в&nbsp;отделениях, обслуживающих юридических лиц и&nbsp;индивидуальных предпринимателей.
    p.t-small.c-gray80 Банк предоставляет сервис «Кредитный потенциал» юридическим лицам и&nbsp;индивидуальным предпринимателям. Не является публичной офертой. Перечень продуктов банка, входящих в&nbsp;состав общего лимита кредитного потенциала: «Оборотный кредит», «Инвестиционный кредит», «Кредитная бизнес-карта», «Гарантия». Банк оставляет за собой право расширить в&nbsp;одностороннем порядке перечень кредитных продуктов, входящих в&nbsp;общий лимит кредитного потенциала. Сочетание кредитных продуктов и&nbsp;их сумма для конкретного клиента в&nbsp;рамках общего лимита кредитного потенциала определяется банком. Между банком и&nbsp;клиентом не возникает никаких финансовых обязательств после расчёта кредитного потенциала. Для получения кредита клиенту необходимо подать отдельную заявку на&nbsp;кредит.
    p.t-small.c-gray80 ПАО Сбербанк. Генеральная лицензия Банка России на&nbsp;осуществление банковских операций № 1481 от 11.08.2015.

  credit-potential-apply-dialog(
    ref="creditPotentialApplyDialog"
    @click:company="clickCompanyLand(assetId)"
    @click:ip="clickIpLand(assetId)"
  )
</template>

<script>
  import { UiIcon, UiBtn, UiExpansionPanel, UiBadge } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CreditPotentialApplyDialog from '@/components/services/CreditPotentialApplyDialog.vue';

  import { clickSBClientLand, clickCompanyLand, clickIpLand } from '@/libs/credit-potential-links';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiBadge,
      UiExpansionPanel,
      InfoCard,
      ApplyServiceAssetCard,
      CreditPotentialApplyDialog,
    },

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
      assetId: { type: String, default: undefined },
    },

    methods: {
      clickSBClientLand,
      clickCompanyLand,
      clickIpLand,

      applyForService() {
        this.$emit('click:apply');
      },
    },
  };
</script>

<style lang="scss">
  .service-credit-potential {
    &__header {
      padding: 104px 16px 68px !important;
      background-color: c('gray100', 'light');
      background-repeat: no-repeat;
      background-size: cover;
      background-image: linear-gradient(to bottom, rgba(37, 40, 43, 0.7), rgba(37, 40, 43, 0.7)),
        url('~@/assets/credit-potential-bg.jpg');
      border-radius: 0 0 12px 12px;

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__section2 {
      background: url('~@/assets/credit-potential-bg2.jpg') no-repeat white;
      background-size: cover;
      border-radius: 12px;
      padding-bottom: 64px;

      .card-sheet {
        border-radius: 8px;
        padding: 32px 24px 40px;
      }
    }

    &__advantages-block {
      .card-sheet {
        box-shadow: 0px 2px 16px 0px rgba(73, 82, 116, 0.08),
          0px 1px 2px 0px rgba(73, 82, 116, 0.08);
      }
    }

    .t-caption-middle {
      line-height: 20px;
    }

    .sberlot-advantages__icon-wrapper {
      padding: 16px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 2px 16px 0px rgba(73, 82, 116, 0.08);
      margin-right: 16px;
    }

    .service-page-dark-card {
      border-radius: 12px;
      color: c('white', 'light');
      background: linear-gradient(180deg, #404244 0%, #0e0f10 100%);
    }
  }
</style>
