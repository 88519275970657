<template lang="pug">
.page-landing.page-landing--credit.service-credit
  section.page-landing__section.page-landing__section--dark.service-credit__header
    h1.t-h1-big.mb-3.c-white {{ service.title }}
    p.t-subtitle.c-gray5.mb-10 {{ service.description }}
    .d-flex.align-center.mb-4
      .asset-price.asset-price--large.c-white {{ service.tariff_price_description }}

    template(v-if="asset")
      ui-btn.mb-3(
        large
        primary
        width="100%"
        @click="apply"
      ) Отправить заявку

    template(v-else)
      .service-credit__header-info
        ui-icon.c-primary(name="info" left)
        .t-caption-middle.c-gray10 Оформите заявку на странице актива

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Преимущества
    .d-flex.align-center.mb-6
      circle-icon(color="gray10")
        ui-icon(name="umbrella")
      .ml-4
        p.t-normal-bold.mb-1.c-black100 Безопасно
        p.t-middle.mb-0.c-black100 Процедура торгов прозрачна, Используется аккредитив

    .d-flex.align-center.mb-6
      circle-icon(color="gray10")
        ui-icon(name="wallet")
      .ml-4
        p.t-normal-bold.mb-1.c-black100 Выгодно
        p.t-middle.mb-0.c-black100 Документы подготовят арбитражный управляющий и сотрудник банка

    .d-flex.align-center.mb-6
      circle-icon(color="gray10")
        ui-icon(name="files-2")
      .ml-4
        p.t-normal-bold.mb-1.c-black100 Удобно
        p.t-middle.mb-0.c-black100 Процедура торгов прозрачна, Используется аккредитив

  section.page-landing__section.service-credit-drpa__report
    h2.t-h1-mobile.mb-8.c-white Условия выдачи кредита
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="calendar-clock")
      p.t-caption-middle.mb-1.c-black100 Сумма ипотеки – от 300 тыс до 100 млн руб.
      p.t-caption-middle.mb-0.c-black100 Срок – от 1 до 30 лет

    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="percent-circle")
      p.t-caption-middle.mb-1.c-black100 Первоначальный взнос – от 10%*
      p.t-caption-middle.mb-3.c-black100 Процентная ставка – от 22.9%*
      p.t-caption-xsmall.mb-0.c-black100 * Не является публичной офертой. Финальные условия кредита определяются при заключении договора.

    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="shield-lock")
      p.t-caption-middle.mb-0.c-black100 Обеспечение – залог приобретаемого на торгах имущества
    info-card.mb-4(title="Особые условия")
      .t-caption-middle.c-black100 Невозможно использовать материнский капитал для первоначального взноса, а также не подходит под условия военной ипотеки. Материнский капитал может быть использован для погашения заемной части.

  section.page-landing__section
    h2.t-h1-mobile.mb-8 Три шага для получения кредита
    info-card.mb-4(title="Оформить заявку на кредит")
      template(#image)
        .d-flex
          img(src="~@/assets/96/messages.svg")
          .t-huge-number.ml-auto.c-gray5 1
    info-card.mb-4(title="Победить на торгах")
      template(#image)
        .d-flex
          img(src="~@/assets/96/sell-2.svg")
          .t-huge-number.ml-auto.c-gray5 2
      p.t-caption-middle.mb-0.c-black100 Поможет
        a.link--no-underline.ml-1(:href="sberlotServiceLink" target="_blank") Агент на торгах
    info-card.mb-4(title="Оформить сделку")
      template(#image)
        .d-flex
          img(src="~@/assets/96/to-rent.svg")
          .t-huge-number.ml-auto.c-gray5 3

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Об агенте
    img.mb-6(src="~@/assets/logos/sber-logo.svg")
    .t-h5.c-black100.mb-3 ПАО Сбербанк
    p.t-normal.c-text.mb-0 ПАО Сбербанк — крупнейшний банк в России, Центральной и Восточной Европе, один из ведущих международных финансовых институтов

  section.page-landing__section
    how-to-order-card(v-if="!asset")
      | Выберите актив, участвующий в процедуре банкротства. На странице актива нажмите кнопку "Получить кредит"
    apply-service-asset-card(v-else :asset="asset" @click="apply")
</template>

<script>
  import { UiIcon, UiBtn, UiExpansionPanel } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import HowToOrderCard from '@/components/pages/HowToOrderCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiExpansionPanel,
      InfoCard,
      CircleIcon,
      HowToOrderCard,
      ApplyServiceAssetCard,
    },
    inject: ['requireAuth'],

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    computed: {
      sberlotServiceLink() {
        return this.$path.service('sberlot', this.asset?.short_id);
      },
    },

    methods: {
      apply() {
        if (!this.asset) return;

        if (this.asset.politics.can_ask) {
          window.location = this.$path.creditServiceApplication(this.asset.short_id);
        } else {
          this.requireAuth('Чтобы заказать услугу необходимо войти или зарегистрироваться.');
        }
      },
    },
  };
</script>

<style lang="scss">
  .service-credit {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/sberlot-bg.jpeg') no-repeat white;
      background-size: cover;

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }
  }
</style>
