<template lang="pug">
.page-landing.page-landing--agroai.service-agroai
  section.service-agroai__header.page-landing__section.page-landing__section--dark
    .service-agroai__header-logo
      img(src="~@/assets/logos/sber-analitic-logo.svg")
      img(src="~@/assets/logos/sberai-logo.svg")
    h1.t-h1-big.mb-3.c-white Оценка севооборота сельхоз земель
    p.t-subtitle.c-gray5.mb-10 Узнайте историю поля без агрохимического анализа — только с использованием космических снимков и моделей машинного обучения

    template(v-if="asset")
      ui-btn.mb-3(
        large
        primary
        width="100%"
        @click="apply"
      ) Отправить заявку
      .d-flex.align-center
        ui-icon.c-primary(name="info" left)
        .t-small.c-gray10 Стоимость вы сможете оговорить с менеджером

    template(v-else)
      .service-agroai__header-info
        ui-icon.c-primary(name="info" left)
        div
          .t-caption-middle.c-gray10.mb-1 Закажите услугу на странице актива
          .t-small.c-black60 Стоимость вы сможете оговорить с менеджером

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Преимущества
    .d-flex.mb-6
      circle-icon(color="gray10")
        ui-icon(name="map-search")
      .ml-4
        p.t-normal-bold.c-black100.mb-2 Объективная оценка
        p.t-middle.mb-0.c-text Анализ происходит по фактическим снимкам с помощью моделей машинного обучения
    .d-flex.mb-6
      circle-icon(color="gray10")
        ui-icon(name="time")
      .ml-4
        p.t-normal-bold.c-black100.mb-2 Быстрое принятие решений
        p.t-middle.mb-0.c-text Оценка истории поля возможна в течение нескольких дней после подписания договора
    .d-flex.mb-6
      circle-icon(color="gray10")
        ui-icon(name="text-box")
      .ml-4
        p.t-normal-bold.c-black100.mb-2 Актуальные данные
        p.t-middle.mb-0.c-text Еженедельное обновление снимков участка
    .d-flex
      circle-icon(color="gray10")
        ui-icon(name="wallet")
      .ml-4
        p.t-normal-bold.c-black100.mb-2 Объективная оценка
        p.t-middle.mb-0.c-black80 Анализ происходит по фактическим снимкам с помощью моделей машинного обучения

  section.page-landing__section.service-agroai__report
    h2.t-h1-mobile.mb-8.c-white В отчете вы получите:
    info-card.mb-4(title="Определение культур")
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="barley")
      | Выявление типов культивируемых растений на заданной территории с точностью до 90%. Модуль поддерживает 15 наиболее распространенных в России культур.
    info-card.mb-4(title="Прогноз урожайности")
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="increase")
      | Предсказание урожайности в текущем сезоне по любой из девяти распространенных в России культур. Средняя точность прогноза — 85%.
    info-card.mb-4(title="Оценка поля")
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="image-search-outline")
      | Ретроспективный анализ культур прошлых лет для определения качества поля

  section.page-landing__section
    h2.t-h1-mobile.mb-8 Этапы работы
    info-card.mb-4(title="Выберите интересующий участок земли на Portal DA")
      template(#image)
        .d-flex
          img(src="~@/assets/96/field.svg")
          .t-huge-number.ml-auto.c-gray5 1
      | Оценку севоборота сельхоз земель можно получить по любому полю, представленому на портале
    info-card.mb-4(title="Подайте заявку")
      template(#image)
        .d-flex
          img(src="~@/assets/96/messages.svg")
          .t-huge-number.ml-auto.c-gray5 2
      | Подайте заявку на портале, указав выбранный объект. Менеджер свяжется с вами для уточнения параметров и заключения договора (удаленно)
    info-card.mb-4(title="Анализ и формирование отчета")
      template(#image)
        .d-flex
          img(src="~@/assets/96/service.svg")
          .t-huge-number.ml-auto.c-gray5 3
      | Искусственный интеллект на основе данных космических спутников сформирует анализ истории севооборота приобретаемого поля
    info-card.mb-4(title="Получите отчет через 5-7 дней")
      template(#image)
        .d-flex
          img(src="~@/assets/96/rent.svg")
          .t-huge-number.ml-auto.c-gray5 4
      | Сроки и форма получения исследования зависят от выбранного набора услуг и определяются индивидуально

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 О сервисе
    img.mb-6(src="~@/assets/logos/sber-analitic-logo.svg")
    .t-h5.c-black100.mb-3 СберАналитика
    p.t-normal.c-text.mb-0 СберАналитика собирает и анализирует актуальную информацию о состоянии отраслей и рынка в российских городах. Компания предоставляет информационно-аналитические услуги на основе больших данных из источников банка и партнёров с применением передовых технологий машинного обучения, обработки и визуализации данных

  section.page-landing__section
    how-to-order-card(v-if="!asset")
      | Выберите актив, участвующий в торгах. На странице актива в блоке услуги нажмите «Заказать»
    apply-service-asset-card(v-else :asset="asset" @click="apply")
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import HowToOrderCard from '@/components/pages/HowToOrderCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';

  export default {
    components: { UiIcon, UiBtn, CircleIcon, InfoCard, HowToOrderCard, ApplyServiceAssetCard },

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    methods: {
      apply() {
        this.$emit('click:apply');
      },
    },
  };
</script>

<style lang="scss">
  .service-agroai {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/agroai-bg.jpeg') no-repeat white;
      background-size: cover;

      &-logo {
        display: flex;
        margin-bottom: 24px;

        img:first-child {
          border-right: 1px solid c('black80', 'light');
          margin-right: 16px;
          padding-right: 16px;
        }
      }

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__report {
      background: url('~@/assets/agroai-bg2.jpeg') no-repeat white;
      background-size: cover;
    }
  }
</style>
