<template lang="pug">
.page-landing.page-landing--enforcement-sberlot.service-enforcement-sberlot
  section.page-landing__section.page-landing__section--dark.service-enforcement-sberlot__header
    h1.t-h1-big.mb-3.c-white {{ service.title }}
    p.t-subtitle.c-gray5.mb-10 {{ service.description }}
    .d-flex.align-center.mb-4
      .asset-price.asset-price--large.c-white {{ service.tariff_price_formatted }}
      .t-small.c-text--dimmed.ml-3(style="width: 110px; line-height: 13px;") Стоимость агентских услуг

    template(v-if="asset")
      ui-btn.mb-3(
        large
        primary
        width="100%"
        @click="applyForService"
      ) Отправить заявку

    template(v-else)
      .service-enforcement-sberlot__header-info
        ui-icon.c-primary(name="info" left)
        .t-caption-middle.c-gray10 Закажите услугу на странице актива

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Для самостоятельного участия в торгах необходимо:
    .d-flex.mb-6
      circle-icon(color="gray10")
        ui-icon(name="file-settings-old")
      .ml-4.mt-2
        p.t-middle.mb-0.c-black80 Получить электронную цифровую подпись
    .d-flex.align-baseline.mb-6
      circle-icon(color="gray10")
        ui-icon(name="user-add-old")
      .ml-4.mt-2
        p.t-middle.mb-0.c-black80 Зарегистрироваться на площадке
    .d-flex.align-baseline.mb-6
      circle-icon(color="gray10")
        ui-icon(name="auction")
      .ml-4.mt-2
        p.t-middle.mb-0.c-black80 Принять участие в торгах

  section.page-landing__section.page-landing__section--dark.service-enforcement-sberlot__section2
    h2.t-h1-mobile.mb-8.c-white Агент по торгам возьмет на себя весь процесс от подачи заявки до участия в торгах
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="case-check")
      | Агент на торгах в исполнительном производстве поможет Вам приобрести интересуемый лот, сэкономив Ваше время и деньги
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="auction-right")
      | Мы за Вас проходим регистрацию на электронной торговой площадке, подаем заявку на участие в торгах, делаем ставки во время торгов
    info-card.mb-4
      template(#image)
        circle-icon(color="primary40" checked)
          ui-icon.c-white(name="pen-plus")
      | Вам останется только подписать договор купли-продажи и радоваться приобретению желаемого лота.

  section.page-landing__section.page-landing__section--white
    h2.t-h2-mobile.mb-8.c-black100 Об агенте
    img.mb-6(src="~@/assets/logos/sber-logo.svg")
    .t-h5.c-black100.mb-3 ПАО Сбербанк
    p.t-normal.c-text.mb-0 ПАО Сбербанк — крупнейшний банк в России, Центральной и Восточной Европе, один из ведущих международных финансовых институтов

  section.page-landing__section
    how-to-order-card(v-if="!asset")
      | Выберите актив, участвующий в торгах. На странице актива в блоке услуги нажмите «Заказать»
    apply-service-asset-card(v-else :asset="asset" @click="applyForService")
</template>

<script>
  import { UiIcon, UiBtn, UiExpansionPanel } from '@/uikit';
  import InfoCard from '@/components/pages/InfoCard.vue';
  import HowToOrderCard from '@/components/pages/HowToOrderCard.vue';
  import ApplyServiceAssetCard from '@/components/pages/ApplyServiceAssetCard.vue';
  import CircleIcon from '@/components/pages/CircleIcon.vue';

  export default {
    components: {
      UiIcon,
      UiBtn,
      UiExpansionPanel,
      InfoCard,
      CircleIcon,
      HowToOrderCard,
      ApplyServiceAssetCard,
    },

    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    methods: {
      applyForService() {
        this.$emit('click:apply');
      },
    },
  };
</script>

<style lang="scss">
  .service-enforcement-sberlot {
    &__header {
      padding: 104px 16px 68px !important;
      background: url('~@/assets/sberlot-bg.jpeg') no-repeat white;
      background-size: cover;

      &-info {
        display: flex;
        align-items: center;
        background: rgba(37, 40, 43, 0.8);
        border-radius: 3px;
        padding: 12px 16px 10px 14px;
      }
    }

    &__section2 {
      background: url('~@/assets/enforcement-sberlot-bg2.jpeg') no-repeat white;
      background-size: cover;
    }
  }
</style>
