<template lang="pug">
.how-to-order-card
  h2.t-h1-mobile.mb-4.c-white Как заказать?
  p.t-normal.c-black10.mb-4
    slot
  ui-btn(
    color="white"
    dark
    preset="secondary"
    :to="$path.catalog()"
  ) Перейти в каталог
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard';

  export default {
    components: { UiIcon, UiBtn, AssetCard },
    props: {
      serviceName: { type: String, default: undefined },
    },
  };
</script>

<style lang="scss">
  .how-to-order-card {
    position: relative;
    border-radius: 4px;
    padding: 56px 24px;
    background-color: c('black100', 'light');

    .v-btn.btn-secondary {
      border: 1px solid c('primary40', 'light') !important;
      color: white !important;
    }
  }
</style>
