<template lang="pug">
.service-order-card(:class="classes")
  .service-order-card__wrapper
    template(v-if="asset")
      .service-order-card__asset-card
        asset-card(:asset="asset")
      .service-order-card__content
        slot(name="apply")
          ui-btn.mb-5(
            large
            primary
            width="100%"
            @click="$emit('click')"
          ) Отправить заявку

    template(v-else)
      .service-order-card__img
        img(src="~@/assets/asset-cards-footer.png")
      .service-order-card__content
        h2.t-h1-mobile.mb-4.c-white Как заказать услугу?
        slot(name="howto")
          .t-normal.c-gray10 Выберите интересующий вас актив в каталоге. На странице актива нажмите кнопку с интересующей Вас услугой.

        ui-btn.mt-5(
          dark
          preset="primary_outlined"
          :to="$path.catalog()"
          width="100%"
        ) Перейти в каталог
</template>

<script>
  import { UiIcon, UiBtn } from '@/uikit';
  import AssetCard from '@/components/asset/AssetCard';

  export default {
    components: { UiIcon, UiBtn, AssetCard },
    props: {
      service: { type: Object, default: undefined },
      asset: { type: Object, default: undefined },
    },

    computed: {
      classes() {
        return [{ 'service-order-card--with-asset': this.asset }];
      },
    },
  };
</script>

<style lang="scss">
  .service-order-card {
    position: relative;
    padding-top: 38px;

    .service-order-card__wrapper {
      border-radius: 12px;
      background: linear-gradient(180deg, #404244 0%, #0e0f10 100%);
    }

    .service-order-card__img {
      padding-left: 24px;
      padding-right: 24px;

      position: relative;
      transform: translateY(-38px);
      margin-bottom: -38px;

      img {
        width: 100%;
      }
    }

    .service-order-card__content {
      padding: 40px 24px 48px;
    }
  }

  .service-order-card.service-order-card--with-asset {
    padding-top: 0;

    .service-order-card__content {
      padding: 40px 16px 48px;
    }
  }
</style>
